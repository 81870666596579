
@import 'assets/resets';



html, body {
	height: 100%;
}

body {
	color: white;
	background: #000;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Jura', sans-serif;
	text-transform: uppercase;
	font-weight: 400;
}

header,
main,
footer {
	width: 100%;
	max-width: 1920px;
	padding: 0 30px;
	text-align: center;
	
}

header {
	margin-top: 3rem;
	// margin-bottom: 3rem;
	@media (min-width: 900px) {
		margin-top: 5rem;
	}
	h1 {
		img {
			max-width: 300px;
			width: 170px;
			display: block;
			margin: 0 auto;

			@media (min-width: 768px) {
				width: 240px;
			}
		}
	}
}

main {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	@media (min-width: 768px) {
		// justify-content: center;
		margin-bottom: 2rem;
	}

	@media (min-width: 900px) {
		margin-bottom: 4rem;
		margin-top: 0;
		justify-content: center;
	}
}


.hero {
	position: relative;
	margin-bottom: 0.5rem;

	@media (min-width: 768px) {
		margin-bottom: 0;
	}

	@media (min-width: 900px) {
		margin-bottom: 1rem;
	}

	@media (min-width: 1900px) {
		margin-bottom: 0;
	}
	
	h2 {
		position: absolute;
		width: 100%;
		text-align: center;
		left: 0;
		bottom: 1rem;
		z-index: 2;
		font-weight: 700;

		@media (min-width: 768px) {
			font-size: 1.4rem;
			bottom: 2rem;
		}

		@media (min-width: 900px) {
			font-size: 2rem;
			position: relative;
			// bottom: 0;
			margin-top: 4rem;
			
		}

		@media (min-width: 1920px) {
			font-size: 3rem;
		}

		
	}
	.seconds {
		position: relative;
		z-index: 1;
		font-weight: 300;
		font-size: 44vw;
		line-height: 1;
		position: relative;

	

		@media (min-width: 768px) {
			font-size: 36vw;
			margin-top: -1rem;
		}

		@media (min-width: 900px) {
			margin-top: -4rem;
		}

		@media (min-width: 1920px) {
			font-size: 50rem;
			margin-top: -9rem;
		}
	
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			position: absolute;
			background: rgb(0,0,0);
		background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
		background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

		@media (min-width: 900px) {
			background: rgb(0,0,0);
background: -moz-linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
		}
			
		}
	}
}


.countdown {
	font-size: 3rem;
	font-weight: 700;
	@media (min-width: 768px) {
		font-size: 3.6rem;
	}

	

	@media (min-width: 900px) {
		display: flex;
		justify-content: center;

		margin-top: -12rem;
		z-index: 2;
		position: relative;

		> div {
			// margin: 0 2rem;
		}
	}

	@media (min-width: 1900px) {
		font-size: 6rem;
		margin-top: -18rem;
	}

	> div {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (min-width: 900px) {
			flex-direction: row;

			.div {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		&:after {
			content: "";
			width: 1px;
			height: 10px;
			background: rgba(white, 0.75);
			display: block;
			margin-top: 10px;
			margin-bottom: 4px;

			@media (min-width: 768px) {
				margin-top: 14px;
				margin-bottom: 8px;
			}

			@media (min-width: 900px) {
				height: 1px;
				width: 30px;
				margin: 0 30px;
			}
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	.title,
	.loading {
		font-size: 0.7rem;
		font-weight: 300;
		color: rgba(white, 0.75);
		letter-spacing: 6;

		@media (min-width: 768px) {
			font-size: 0.8rem;
		}

		@media (min-width: 900px) {
			font-size: 1.3rem;
		}

		@media (min-width: 1900px) {
			font-size: 1.5rem;
		}
	}

	
}

footer {
	position: relative;
	z-index: 4;
	margin-top: auto;
	display: flex;
	flex-direction: column;
	color: rgba(white, 0.75);
	font-size: 0.8rem;
	margin-top: 1.5rem;
	padding-bottom: 3rem;
	@media (min-width: 768px) {
		margin-top: 0;
	}
}
