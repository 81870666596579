html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring, button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[hidden], template {
  display: none;
}

*, :after, :before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a, button {
  color: inherit;
  cursor: pointer;
}

button {
  background-color: #0000;
  border-width: 0;
  padding: 0;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  border: 0;
  margin: 0;
  padding: 0;
}

dd, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  color: #fff;
  text-transform: uppercase;
  background: #000;
  flex-direction: column;
  align-items: center;
  font-family: Jura, sans-serif;
  font-weight: 400;
  display: flex;
}

header, main, footer {
  width: 100%;
  max-width: 1920px;
  text-align: center;
  padding: 0 30px;
}

header {
  margin-top: 3rem;
}

@media (min-width: 900px) {
  header {
    margin-top: 5rem;
  }
}

header h1 img {
  max-width: 300px;
  width: 170px;
  margin: 0 auto;
  display: block;
}

@media (min-width: 768px) {
  header h1 img {
    width: 240px;
  }
}

main {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  display: flex;
}

@media (min-width: 768px) {
  main {
    margin-bottom: 2rem;
  }
}

@media (min-width: 900px) {
  main {
    justify-content: center;
    margin-top: 0;
    margin-bottom: 4rem;
  }
}

.hero {
  margin-bottom: .5rem;
  position: relative;
}

@media (min-width: 768px) {
  .hero {
    margin-bottom: 0;
  }
}

@media (min-width: 900px) {
  .hero {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1900px) {
  .hero {
    margin-bottom: 0;
  }
}

.hero h2 {
  width: 100%;
  text-align: center;
  z-index: 2;
  font-weight: 700;
  position: absolute;
  bottom: 1rem;
  left: 0;
}

@media (min-width: 768px) {
  .hero h2 {
    font-size: 1.4rem;
    bottom: 2rem;
  }
}

@media (min-width: 900px) {
  .hero h2 {
    margin-top: 4rem;
    font-size: 2rem;
    position: relative;
  }
}

@media (min-width: 1920px) {
  .hero h2 {
    font-size: 3rem;
  }
}

.hero .seconds {
  z-index: 1;
  font-size: 44vw;
  font-weight: 300;
  line-height: 1;
  position: relative;
}

@media (min-width: 768px) {
  .hero .seconds {
    margin-top: -1rem;
    font-size: 36vw;
  }
}

@media (min-width: 900px) {
  .hero .seconds {
    margin-top: -4rem;
  }
}

@media (min-width: 1920px) {
  .hero .seconds {
    margin-top: -9rem;
    font-size: 50rem;
  }
}

.hero .seconds:before {
  content: "";
  width: 100%;
  height: 100%;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#000000", endColorstr= "#000000", GradientType= 1);
  background: linear-gradient(#0000 0%, #000 80% 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 900px) {
  .hero .seconds:before {
    filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#000000", endColorstr= "#000000", GradientType= 1);
    background: linear-gradient(#0000004d 0%, #000 80% 100%);
  }
}

.countdown {
  font-size: 3rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  .countdown {
    font-size: 3.6rem;
  }
}

@media (min-width: 900px) {
  .countdown {
    z-index: 2;
    justify-content: center;
    margin-top: -12rem;
    display: flex;
    position: relative;
  }
}

@media (min-width: 1900px) {
  .countdown {
    margin-top: -18rem;
    font-size: 6rem;
  }
}

.countdown > div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 900px) {
  .countdown > div {
    flex-direction: row;
  }

  .countdown > div .div {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

.countdown > div:after {
  content: "";
  width: 1px;
  height: 10px;
  background: #ffffffbf;
  margin-top: 10px;
  margin-bottom: 4px;
  display: block;
}

@media (min-width: 768px) {
  .countdown > div:after {
    margin-top: 14px;
    margin-bottom: 8px;
  }
}

@media (min-width: 900px) {
  .countdown > div:after {
    height: 1px;
    width: 30px;
    margin: 0 30px;
  }
}

.countdown > div:last-child:after {
  display: none;
}

.countdown .title, .countdown .loading {
  color: #ffffffbf;
  letter-spacing: 6px;
  font-size: .7rem;
  font-weight: 300;
}

@media (min-width: 768px) {
  .countdown .title, .countdown .loading {
    font-size: .8rem;
  }
}

@media (min-width: 900px) {
  .countdown .title, .countdown .loading {
    font-size: 1.3rem;
  }
}

@media (min-width: 1900px) {
  .countdown .title, .countdown .loading {
    font-size: 1.5rem;
  }
}

footer {
  z-index: 4;
  color: #ffffffbf;
  flex-direction: column;
  margin-top: 1.5rem;
  padding-bottom: 3rem;
  font-size: .8rem;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  footer {
    margin-top: 0;
  }
}

/*# sourceMappingURL=index.07261fd5.css.map */
